<template>
	<AccountLogin />
</template>

<script setup>
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n'
import { BASE_URL, COMPANY_NAME } from '~/constants/index.js'

const { t } = useI18n({
	useScope: 'global'
})

useHead({
	title: t('login.meta-title'),
	meta: [
		{ name: 'description', content: t('login.description', { COMPANY_NAME }) },
		{ name: 'url', content: `${BASE_URL}/login` }
	]
})

</script>
